import React, { useState, useEffect } from "react";
import { AppContext } from "./AppContext";

const AppProvider = ({ children }) => {
  const [country, setCountry] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("country") || "Argentina";
    }
    return "Argentina";
  });

  const DOMAINS = Object.freeze({
    Argentina: '.ar',
    Chile: '.cl',
    Uruguay: '.uy',
    Bolivia: '.bo',
    Paraguay: '.py'
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const location = window.location;
      let foundCountry = null; // Variable para almacenar el país detectado

      // Si el hostname es localhost, asigna Argentina
      if (location.hostname === 'localhost') {
        foundCountry = 'Argentina';
      } else {
        // Itera sobre el objeto DOMAINS para encontrar una coincidencia de dominio
        for (const [key, value] of Object.entries(DOMAINS)) {
          if (location.hostname.endsWith(value)) {
            foundCountry = key;
            break;
          }
        }
      }

      if (foundCountry) {
        localStorage.setItem("country", foundCountry);
        setCountry(foundCountry); // Actualiza el estado con el país detectado
      } else {
        // Si no hay coincidencia, usa el valor de estado actual para localStorage
        localStorage.setItem("country", country);
      }
    }
  }, [country]); // Dependencia del efecto sobre el estado del país

  const contextValue = {
    country,
    setCountry,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
